import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function WhatIfI() {
  document.title = "Melissa Gerhold - What If I? book";
  return (
    <>

<Container fluid className="bg-dark text-white p-3">
        <Link
          to="/"
          className="btn btn-primary text-bg-dark"
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
        >
          Home
        </Link>
      </Container>


      <Container
        fluid
        className="text-center pb-5 text-white"
        style={{
          backgroundImage: `linear-gradient(rgba(44, 68, 96, 0.75), rgba(0, 0, 0, 0.25)),URL("https://melissagerhold.com/images/ThisSandGetsEverywhere.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <p className="blockquote pt-5">“It takes courage to grow up and become who you really are.”</p>
        <p className="blockquote-footer text-white fw-bold">E.E. Cummings</p>


        <Image
          fluid
          src="https://melissagerhold.com/images/whatif_Wh.png"
          alt="What If I? Title"
          />
   


        <h1 className="h2">Celebrate the wonder of childhood curiosity.</h1>
        {/* <h2 className="h4">Available now on Kindle</h2> */}


        <p className="fs-4 fw-bold">Paperback & Kindle Available Now on Amazon</p>
           
              


        <Button
          style={{ backgroundColor: "#24354f", borderColor: "#F8D397" }}
          as="a"
          href="https://a.co/d/d069jcs"
          target="_blank"
          size="lg"
          rel="noopener noreferrer"
          className="p-3 mt-2"
          >
          Buy Now
        </Button>
        <Button
          style={{ backgroundColor: "#24354f", borderColor: "#F8D397" }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/melissagerholdauthor"
          size="lg"
          className="btn btn-primary ml-2 p-3 mt-2"
          >
          Follow on Facebook
        </Button>{" "}
            
      </Container>

      <Container
        fluid
        style={{ backgroundColor: "#F8D397" }}
        className="p-5 text-center"
      >
        <Image style={{maxHeight:"400px"}} fluid src={require("../img/WII.jpg")} alt="What If I? Cover" />
        {/* <Image
          style={{ maxHeight: "400px" }}
          fluid
          src="https://melissagerhold.com/images/whatifcover600.jpg"
          alt="What If I? Cover"
        /> */}
        <h3 className="mt-5 h2">An Enchanting Bedtime Story</h3>
        <h4 className="h4">
          "What If I?" weaves together elements of nature, sky, and enchanting
          animals to celebrate the wonder of childhood curiosity.
        </h4>
      </Container>

      <Container
        fluid
        className="text-center p-5"
        style={{
          backgroundImage: `URL("https://melissagerhold.com/images/whatifspace2.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Button
          style={{ backgroundColor: "#24354f", borderColor: "#F8D397" }}
          as="a"
          href="https://a.co/d/d069jcs"
          target="_blank"
          size="lg"
          rel="noopener noreferrer"
          className="p-4"
        >
          Buy Now
        </Button>
      </Container>

      <Container
        fluid
        className="text-center p-5 text-white"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5)), URL("https://melissagerhold.com/images/whatifmoon.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="h2 mb-5 fw-bold">All the Details</p>
        <Row>
          <Col md={6} className="p-4">
            <p className="h3">Imagination</p>
            <p>
              Through imaginative conversation, "What If I?" explores the
              journey of self-discover and encourages acceptance.
            </p>
          </Col>
          <Col md={6} className="p-4">
            <p className="h3">Possibilities</p>
            <p>
              This story invites young readers to explore the magnificent world,
              uncover endless possibilitites, and discover who they truly are.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="p-4">
            <p className="h3">Discussion</p>
            <p>
              Talk about your own "What If I?" questions with someone you love.
            </p>
          </Col>
          <Col md={6} className="p-4">
            <p className="h3">One-of-a-Kind</p>
            <p>What makes you unique?</p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="text-center p-5">
        <p className="h1 mt-5">Wondrous Possibilities</p>
        <p className="lead" style={{ fontSize: "2em" }}>
          Discovering who you are can be complicated, but with encouragement and
          love, the journey can be wonderous and exciting.
        </p>

        <Button
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
          as="a"
          href="https://a.co/d/d069jcs"
          target="_blank"
          size="lg"
          rel="noopener noreferrer"
          className="p-3 mt-4 mb-5"
        >
          Buy Now
        </Button>
      </Container>

      <Container
        fluid
        className="text-center pb-5 text-white"
        style={{
          backgroundImage: `URL("https://melissagerhold.com/images/whatifcat.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Image
          fluid
          src="https://melissagerhold.com/images/whatif_Wh.png"
          alt="What If I?"
        />

        <Button
          style={{ backgroundColor: "#24354f", borderColor: "#F8D397" }}
          as="a"
          href="https://a.co/d/d069jcs"
          target="_blank"
          size="lg"
          rel="noopener noreferrer"
          className="p-3 mt-2"
        >
          Buy Now
        </Button>
      </Container>

      <Container fluid className="bg-dark text-white p-3">
        <Link
          to="/"
          className="btn btn-primary text-bg-dark"
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
        >
          Home
        </Link>
      </Container>
    </>
  );
}
