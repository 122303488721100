import { Container, Image, Row, Col, Button } from "react-bootstrap";
import Phant from "../components/phantLogo";
import Bio from "../components/Bio";
// import Bird from '../components/bird';
import Links from "../components/Links";
import ShowHome from "../show/ShowHome";
import MailChimpSubscribe from "../components/MailChimpSubscribe";

import DoorsIntro from "./doorsShow/DoorsIntro";
// import FineArt from '../components/FineArt';
// import Design from '../components/Design';
// import Photography from '../components/Photography';
// import Test from '../components/Test';

// import {useState} from 'react'

const HomePage = () => {
  document.title = "Melissa Gerhold - Artist";

  //     const [fillColors, setFillColors] = useState(Array(10).fill('white'))
  // const [currentColor, setCurrentColor] = useState('blue')

  // const onFillColor = (i) => {
  //     let newFillColors = fillColors.slice(0)
  //     newFillColors[i] = currentColor
  //     setFillColors(newFillColors)
  // }

  return (
    <>
      {/*  */}
      {/* <Container fluid className="bg-green py-5 d-flex justify-content-center">
<div
style={{width:"15rem", height:"100vh"}}>
<Test fillColors={fillColors} onFill={onFillColor} />
</div>

    https://uxdesign.cc/5-steps-to-create-a-simple-digital-coloring-book-in-react-3d4f5b2af822
    </Container> */}

      <Links />
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center p-3 text-white"
        style={{ backgroundColor: "#dc8911" }}
      >
        {/* <p
          style={{
            fontSize: "4rem",
            color: "white",
            fontFamily: "above-the-sky-script",
          }}
          className="lh-sm"
        >
          MelissaGerhold
        </p> */}
        <p className="fw-bold display-5 ">Melissa Gerhold</p>
        <p className="fs-3" style={{ lineHeight: "0px" }}>
          Artist
        </p>
      </Container>

<DoorsIntro />


      <Container className="bg-dark text-light my-5">
        <Row>
          <Col md={6} className="p-5 text-center">
          <Image fluid src={require("../img/WII.jpg")}  alt="What If I? Cover" />
          </Col>
          <Col className="d-flex justify-content-center align-items-center p-5">
            <div className="text-center">
              <Image
                fluid
                src="https://melissagerhold.com/images/whatif_Wh.png"
                alt="What If I?"
              />
              <p className="fs-4 fw-bold">Paperback & Kindle Available Now on Amazon</p>

              <p>
                {" "}
                <i className="fw-bold">What If I?</i> weaves together elements
                of nature, sky, and enchanting animals to celebrate the wonder
                of childhood curiosity.
              </p>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href="https://a.co/d/d069jcs"
                className="btn btn-primary text-bg-dark"
                style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
                as="a"
              >
                <strong>Order On Amazon</strong>
              </Button>{" "}
              <Button
                href="/what-if-i-book"
                className="btn btn-primary text-bg-dark"
                style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
                as="a"
              >
                <strong>Find Out More</strong>
              </Button>
              {/* <Button
className='btn btn-primary text-bg-dark ml-2' style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/melissagerholdauthor"
        
          >
          <strong>Follow on Facebook</strong>
        </Button>{" "} */}
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="bg-dark text-light my-5">
        <Row>
          <Col md={5} className="p-5">
            <Image fluid src={require("../img/CWHS.jpg")} />
          </Col>
          <Col className="d-flex justify-content-center align-items-center p-5">
            <div className="text-center">
              <p className="fs-2 fw-bold">The Child Who Hated Spring</p>
              <p className="fs-3">Kindle and Paperback Now Available</p>
              <p>
                Discover the power of reframing in challenging circumstances
                with <i>The Child Who Hated Spring</i>. This heartwarming tale
                teaches children to seek out hidden treasures in unpleasant
                situations.
              </p>
              <Button
                className="btn btn-primary text-bg-dark"
                style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://a.co/d/3BSMUdA"
                variant="outline-secondary"
                as="a"
              >
                <strong>Order On Amazon</strong>
              </Button>{" "}
              <Button
                className="btn btn-primary text-bg-dark"
                style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/melissagerholdauthor"
                variant="outline-secondary"
                as="a"
              >
                <strong>Follow on Facebook</strong>
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="p-5 pt-0 bg-dark text-white homePageImage text-center "
      >
        {/* <Row className='d-flex justify-content-center py-5'>

<Col xl={6} lg={9} md={12} className="justify-items-center">
<Image fluid src={require('../img/venice.jpg')} />
</Col>
</Row> */}
      </Container>

      <ShowHome />

      <Container
        fluid
        className="p-5 text-white d-flex flex-column justify-content-center align-items-center"
        style={{ background: "var(--color1)", minHeight: "40vh" }}
      >
        <p className="fs-2 fw-bold">
          Creating beautiful art to inspire dreams and capture the imagination
        </p>
      </Container>

      <Container
        fluid
        className="m-0 p-5 bg-dark text-white"
        style={{ minHeight: "80vh" }}
      >
        <Row>
          <Col md={6}>
            <Image
              pointerEvents="none"
              onContextMenu="return false;"
              fluid
              src={require("../img/tiger600.jpg")}
            />
          </Col>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <p className="h1 lh-1 fw-bold" style={{ fontSize: "400%" }}>
              Animals
            </p>
            <p className="h1 lh-1 pb-5">Buy on Redbubble</p>
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.redbubble.com/people/mgerhold/shop"
              variant="outline-secondary"
              size="lg"
            >
              <strong>Click Here</strong>
            </Button>{" "}
          </Col>
        </Row>
      </Container>

      {/* <Container fluid className='p-5 text-white d-flex flex-column justify-content-center align-items-center' style={{background:"var(--color1)", minHeight:"40vh"}}>

<p className='fs-4'>Get first access to free content and latest art</p>

<Button
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.redbubble.com/people/mgerhold/shop"
          variant="outline-secondary"
          size="sm"
        >
          <strong>Join the community</strong>
        </Button>
</Container> */}

      {/* <Container className="py-5">
    <Row>
        <Col>
        <Card className='p-4 text-white text-center' style={{background:'#666'}}>
            <p className='fw-bold fs-4'>Animals</p>
            <p>capturing the beauty of wise and wonderful beings in nature</p>
            </Card>
        </Col>
        <Col>
        <Card className='p-4 text-white text-center' style={{background:'#666'}}>
            <p className='fw-bold fs-4'>
            places
            </p>
            <p>beautiful places worldwide inspiring nostalgia and wanderlust</p>
            </Card>
        </Col>
        <Col>
        <Card className='p-4 text-white text-center' style={{background:'#666'}}>
            <p className='fw-bold fs-4'>
            faces
            </p>
            <p>imagining a beautiful world where all of nature lives in harmony</p>
            </Card>
        </Col>
    </Row>
</Container> */}

      <Bio />

      <MailChimpSubscribe />

      <Container
        fluid
        className="
py-5 d-flex justify-content-center"
      >
        <div style={{ width: "8rem" }}>
          <Phant />
        </div>
      </Container>
      {/* <Bird /> */}
      {/* <Design />
<Photography />
<FineArt /> */}
    </>
  );
};

export default HomePage;
