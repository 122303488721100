
function PhantLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 402 480"
      overflow="visible"

    >
      <style>
        {
          ".clr2{fill:#bfd9d2}.clr3{fill:#79b5a4}.clr4{fill:#498574}"
        }
      </style>

      <path
        fill="#bfd9d2"
        d="M211.6 183.2l105.7-2.7-80.9 94.7z"
      />
      <path
        className="clr2"
        d="M227.9 290.5L319 181.2l-24.9 107.4zM321.7 178.7l18 21.5-39.7 79.7zM323.5 177.1l33.4-35.1-15 56.1zM340.2 106.4l14.9 31.6-26.7 30.5zM317.8 85.4l11.5 58.9 9.7-40.5zM333.3 45.2l-14.1 37.9 19 15.8-1.7-18.1zM364.3 51.8l-23 24.4-4.8-29.9zM358 25.1L339.3 42l23.6 6.3zM214 180l46.2-9.7 55.2 7.6zM210 183.5l-100.5-36.4L190 313zM192.7 312.4l18.4-118.6 23.5 81.3zM108.7 149.8l-34.4 81.6 57.3-29.5zM186.2 313.5l-54-108.1-54.3 29.7zM107.3 149.5l-28.1 27-5.7 49.2zM80.5 239.9l104.8 75.4-57.6-17.3zM357.2 147.3l-5.2 19.1-6.1 22.5 14.3-17.5zM116.4 148.7l42 3.7 46.8 27.5z"
      />
      <path
        className="clr3"
        d="M116.4 288.7L31.9 175.8l9.5 77.4zM32.6 174.4l15.8-45.9 29 48.3-6.4 45.7zM106.7 148l-30.8-31.1-23.3 9.8zM78.3 173.1l24.6-23.1-49.2-20.6zM80.3 118.3l50.8 14.2 19.1 17-41.7-3.5zM128.2 300.9l53.6 14.6-88.3 71.3zM115.4 326.4l-19.7 29.8-3.6 27.1zM92.3 391.5l13.6 51.7 45.9 16.4zM95.6 389.4l85.4-70.3-35.7 65.4zM152.5 457.7l11.5-13.4-21-56.4-48.6 4.6z"
      />
      <path className="clr4" d="M218.1 444.8l-51 1.7-10.8 12.4z" />
      <path
        className="clr3"
        d="M183.7 319.9l41.8-26 67.1-2.3zM146.8 388.3l35-64.4 50.6-13.6-24.2 22.1zM263 363.1l-53.3-25.3-61.4 53.2zM167.2 443.2l-15.4-49.6L262 365.5l-46.9 77.3zM213.8 334.1l25.3-25.6 54.2-13.8 49 59.7z"
      />
      <path
        className="clr4"
        d="M302.7 385.3l39.2-27.4-58.2-9.6zM216 337l64.9 12.4 17.9 35.5-32.9-19zM220.1 441.5l27.5.7 51.2-51.9-33.6-22.6zM303.5 395.8l13.8 40.7-30.1 12.6zM253.5 442.8l46.6-50.5-14.4 53.6zM310.5 384l22.4-5.9 8.7-17.4zM89.3 393.4l-5 12.6 10.6 11.5z"
      />
      <path
        className="clr3"
        d="M317.2 81.9l14.1-36.3-11.4 6.5zM321.5 49.5l14.1-7 .3-9.3zM356.5 22.9L337.3 31v9.7zM359.4 26.7l5.9 5.8v19z"
      />
      <path
        className="clr4"
        d="M259.1 445.6c-1.8.5 11.1 6.6 11.1 6.6l13.3-4.3s-22.6-2.7-24.4-2.3zM289 451l28.9-12-9 8.7z"
      />
      <path className="clr2" d="M234.7 277.1l-35 30.9 25.6-15.8z" />
      <path className="clr4" d="M261 227l1.3-11.2 13.1-.7z" />
    </svg>
  )
}

export default PhantLogo
